<template>
  <section id="dashboard">
    <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white">
      <div slot="header" class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr v-model="rangePicker" style="width: 55vw;" :config="{ mode: 'range' }" class="form-control flat-picker bg-transparent border-0 text-white shadow-none" placeholder="YYYY-MM-DD" />
      </div>
      <!-- <b-card-header>
      </b-card-header> -->

      <b-card-body>
        <b-row class="match-height">
          <!-- Transaction Card -->
          <b-col v-for="(row, index) in trData.data" :key="index" lg="3" md="6">
            <transactions :data="row.data" :title="row.name" />
          </b-col>
          <!-- <b-col lg="4" md="6">
            <transactions :data="data.transactionData" :title="'Доход'" />
          </b-col>
          <b-col lg="4" md="6">
            <transactions :data="data.transactionData" :title="'Прибыль'" />
          </b-col>
          <b-col lg="4" md="6">
            <transactions :data="data.transactionData" :title="'Расход'" />
          </b-col> -->
          <!--/ Transaction Card -->

          <!-- <b-col
        xl="8"
        md="6"
      >
        <statistics title="Объем закупок" :data="data.statisticsItems" />
      </b-col> -->
        </b-row>
      </b-card-body>
    </b-card>
    <b-card border-variant="success" header-bg-variant="success" header-text-variant="white">
      <div slot="header" class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr v-model="range2Picker" style="width: 55vw;" :config="{ mode: 'range' }" class="form-control flat-picker bg-transparent border-0 text-white shadow-none" placeholder="YYYY-MM-DD" />

        <div>Текущий статус</div>
      </div>

      <b-card-body>
        <b-row class="match-height">
          <!-- Transaction Card -->
          <b-col v-for="(row, index) in srData.data" :key="index" lg="3" md="6">
            <transactions :data="row.data" :title="row.name" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-row class="match-height">
      <b-col lg="4">
        <devices-status :data="data.devicesStatus" />
      </b-col>
      <!-- Revenue Report Card -->
      <b-col lg="8">
        <using-duration :data="data.revenue" />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="12">
        <profit-chart :data="data.lineAreaChartSpline" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'

import { BCard, BRow, BCol, BCardBody } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

import store from '@/store'

import { getUserData } from '@/auth/utils'
// import Statistics from './cards/Statistics.vue'
import UsingDuration from './cards/UsingDuration.vue'
import DevicesStatus from './cards/DevicesStatus.vue'
import ProfitChart from './cards/ProfitChart.vue'
import Transactions from './cards/Transactions.vue'

export default {
  components: {
    BCard,
    // BCardHeader,
    BRow,
    BCol,
    BCardBody,
    // Statistics,
    UsingDuration,
    DevicesStatus,
    ProfitChart,
    Transactions,
    flatPickr
  },
  data() {
    return {
      data: {}
      // rangePicker: ['2019-05-01', '2019-05-10']
    }
  },
  async created() {
    // data
    this.$http.get('/ecommerce/data').then(response => {
      this.data = response.data

      // ? Your API will return name of logged in user or you might just directly get name of logged in user
      // ? This is just for demo purpose
      const userData = getUserData()
      this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
    })
  },
  setup() {
    const { t } = useI18nUtils()
    const today = moment().format('YYYY-MM-DD')
    const oneWeekAgo = moment()
      .subtract(365, 'd')
      .format('YYYY-MM-DD')
    const rangePicker = ref([oneWeekAgo, today])
    const range2Picker = ref([today, today])
    const trData = ref([])
    const srData = ref([])

    const runblock1data = async (start, end) => {
      try {
        const res = (
          await store.dispatch('app-ecommerce/dash1blockbyDate', {
            start,
            end
          })
        ).data
        trData.value = res
      } catch (error) {
        console.log('runblock1data', error)
      }
      // console.log(countwm)
    }

    const runblock2data = async (start, end) => {
      try {
        const res = (
          await store.dispatch('app-ecommerce/dash2blockbyDate', {
            start,
            end
          })
        ).data
        srData.value = res
      } catch (error) {
        console.log('runblock2data', error)
      }
      // console.log(countwm)
    }
    runblock1data(oneWeekAgo, today)
    runblock2data(today, today)

    const parseRangePickerValue = value => {
      if (Array.isArray(value)) {
        return value
      }
      const [start = '', end = ''] = value.split(' to ')
      return [start, end]
    }

    const rangeDates = computed(() => {
      const [start, end] = parseRangePickerValue(rangePicker.value)
      return { start, end }
    })

    const range2Dates = computed(() => {
      const [start, end] = parseRangePickerValue(range2Picker.value)
      return { start, end }
    })

    watch(rangeDates, async ({ start, end }) => {
      console.log({ start, end })
      if (start && end) {
        try {
          await runblock1data(start, end)
        } catch (error) {
          console.error(error)
        }
      }
    })

    watch(range2Dates, async ({ start, end }) => {
      console.log({ start, end })
      if (start && end) {
        try {
          await runblock2data(start, end)
        } catch (error) {
          console.error(error)
        }
      }
    })
    // watch([rangePicker], ([newA, newB]) => {
    //   console.log({ newA, newB })
    //   const temp = newA.split(' to ')
    //   if (temp[0] !== undefined && temp[1] !== undefined) {
    //     runblock1data(temp[0], temp[1])
    //   }
    //   const temp2 = newB.split(' to ')
    //   if (temp2[0] !== undefined && temp2[1] !== undefined) {
    //     runblock2data(temp2[0], temp2[1])
    //   }
    //   // calcExchange()
    // })
    return {
      rangePicker,
      range2Picker,
      trData,
      srData,
      t
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.card {
  .card-header {
    padding: 0.5rem 1.5rem;
  }
}
</style>
